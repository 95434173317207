import React from 'react';
import { TwitchEmbed, TwitchChat, TwitchClip, TwitchPlayer } from 'react-twitch-embed';

const VideoPlayer = () => {
  return (
    <div>
      <TwitchPlayer
        channel="katelibby"
        id="katelibby"
        theme="dark"
        width="480"
        muted
        onVideoPause={() => console.log(':(')}
      />
    </div>
  );
}

export default VideoPlayer
